import React from "react";
import { Text } from "@atoms/index";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupValidator } from "@lib/yup-validator";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { JWT } from "google-auth-library";

const Moment = require("moment");

const SPREADSHEET_ID = "1-MeuX7Sh6g2npgZc4CU6e-ZltYpk_DAQnBWtIpgbUeI";
const SHEET_ID = process.env.NEXT_PUBLIC_SHEET_ID;
const GOOGLE_CLIENT_EMAIL = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_EMAIL;
const GOOGLE_SERVICE_PRIVATE_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_SERVICE_PRIVATE_KEY;

const Subscribe = () => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Provide a valid Email ID")
      .required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
  } = useForm({ resolver: yupValidator(schema) });

  const serviceAccountAuth = new JWT({
    email: GOOGLE_CLIENT_EMAIL,
    key: GOOGLE_SERVICE_PRIVATE_KEY,
    scopes: ["https://www.googleapis.com/auth/spreadsheets"],
  });

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID, serviceAccountAuth);

  const appendSpreadsheet = async (row) => {
    try {
      // loads document properties and worksheets
      await doc.loadInfo();
      const sheet = doc.sheetsById[SHEET_ID];
      await sheet.addRow(row);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const onSubmit = async (data) => {
    const currentDate = Moment()
      .utcOffset("+05:30")
      .format("DD-MM-YYYY HH:mm A");
    const newRow = {
      Email: data.email,
      Timestamp: currentDate,
    };
    appendSpreadsheet(newRow);
    resetForm();
  };

  return (
    <>
      <Text
        className="text-center "
        htmlTag="h1"
        weight="font-black"
        size="2xl:text-2xl lg:text-2xl text-lg"
        content="Subscribe to get the latest news about us"
      />
      <Text
        className="mt-2 mb-4 text-center capitalize"
        htmlTag="p"
        weight="font-light"
        size="text-xs lg:text-xs"
        color="text-gray-500"
        content="Please drop your email below to get daily update about what we do"
      />
      <div className="relative w-full sm:w-2/4 lg:w-2/5 lg:mt-2 xl:mt-5 ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative flex rounded-md overflow-hidden border border-black w-full">
            <input
              {...register("email")}
              id="subEmail"
              type="email"
              className="flex-grow px-3 py-2 -m-1 text-sm font-medium text-gray-800 border font-roboto focus:outline-none"
              placeholder="Enter Your Email Adress"
            />
            <button
              type="submit"
              className="px-7 py-3 text-sm font-semibold text-white lg:text-base font-roboto bg-primary-900"
            >
              Subscribe
            </button>
          </div>
          <div className="mt-2 ml-1 text-base font-medium text-left text-red-500">
            {errors.email?.message}
          </div>
        </form>
      </div>
    </>
  );
};

export default Subscribe;
