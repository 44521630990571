import Head from "next/head";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Text, Button } from "@atoms/index";
import Image from "next/image";
import Subscribe from "@components/Subscribe";
import { faqs, homePrivacy } from "@helpers/content/home";
import { getAuth, isAuthenticated } from "@services/identity.service";
import Link from "next/link";
import { useRouter } from "next/router";

function Homepage() {
  const auth = getAuth();
  const router = useRouter();
  const [FAQClicked, setFAQClicked] = useState(() =>
    Array(faqs.length).fill(false),
  );
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [showIndex, setShowIndex] = useState(0);
  const videoRef = useRef(null);
  const productDemoUrl = process.env.NEXT_PUBLIC_PRODUCT_DEMO;
  const oneClickUrlExecution = process.env.NEXT_PUBLIC_ONE_CLICK_EXECUTION;

  const changeIndex = () => {
    setShowIndex((prevIndex) =>
      prevIndex === homePrivacy.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const toggleVideo = () => {
    const video = videoRef.current;

    if (video.paused) {
      video.play();
      setIsVideoPlaying(true);
    } else {
      video.pause();
      setIsVideoPlaying(false);
    }
  };

  const handleFAQItemClick = useCallback((index) => {
    setFAQClicked((prevFAQClicked) => {
      const updatedFAQClicked = [...prevFAQClicked];
      updatedFAQClicked[index] = !updatedFAQClicked[index];
      return updatedFAQClicked;
    });
  }, []);

  const handleTelegram = () => {
    return window.open(`https://t.me/btst_tech`, "_blank");
  };

  useEffect(() => {
    const interval = setInterval(changeIndex, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isAuthenticated(auth)) router.push("/adviser-directory");
  }, [auth]);

  return (
    <>
      <Head>
        <title>Trading Ideas by SEBI-registered Advisers - BTST</title>
        <meta
          name="description"
          content="One-stop solution to informed trading and investment decisions for retail investors. Connect with SEBI-Registered Advisers and elevate your stock market experience."
        />
        <meta
          name="keywords"
          content="BTST,Buy Today Sell Tomorrow,Stock Market,SEBI Registered Advisers,Stock Trading Tips,Intraday Trading,Investment Advisery,Stock Recommendations,Demat Account,Broker Accout,Equity Investments,NSE (National Stock Exchange),BSE (Bombay Stock Exchange),Financial Advisery Services,Stock Market Analysis,Risk Management,Technical Analysis,Fundamental Analysis,Stock Market Trends,Nifty 50,Sensex,IPO (Initial Public Offering),Options Trading,Adviser Directory,Research Analyst ,RA,Trading,Expert Advisery,Capital Protection"
        />
      </Head>
      <div className="flex flex-col w-full min-h-screen gap-y-6 ">
        <div className="flex flex-col w-full 2xl:h-[620px] xl:h-[560px] lg:h-[490px] pb-10 lg:pb-0 overflow-hidden lg:justify-between gap-y-5 sm:gap-y-20 lg:flex-row bg-gray-100 lg:px-16 xl:px-32 2xl:px-36">
          <div className="flex flex-col items-start justify-start w-full px-5 pt-6 lg:px-0 sm:px-10 sm:pt-12 lg:pt-20 2xl:pt-24 lg:w-2/5 gap-y-1 xl:gap-y-3">
            <Text
              htmlTag="h1"
              size=" 2xl:text-5xl xl:text-4.5xl lg:text-3xl sm:text-4xl text-3xl"
              fontfamily="font-Slab"
              weight="font-medium"
              content="Hassle-free Stock"
            />
            <Text
              htmlTag="h1"
              size="2xl:text-5xl xl:text-4.5xl lg:text-3xl sm:text-4xl text-3xl "
              fontfamily="font-Slab"
              weight="font-medium"
              content="Market Journey"
            />
            <Text
              htmlTag="p"
              size="xl:text-xl sm:text-lg text-sm"
              color="text-gray-500"
              fontfamily="font-sans"
              weight="font-medium"
              className="relative mt-4 2xl:mt-8 xl:mt-5 lg:mt-5 sm:w-3/4 lg:w-3/4 2xl:w-2/3 "
              content="Join affordable, interactive, and expert-driven advisery groups that put you in control."
            />
            <div className="hidden w-full mt-8 lg:flex lg:gap-x-4 xl:gap-x-6 2xl:gap-x-10">
              <Link href="/login">
                <Button
                  type="primary"
                  Typeof="button"
                  padding="2xl:px-2 px-1 py-2"
                  width=" xl:w-36 2xl:w-40 lg:w-28"
                  className="border shadow-md xl:text-base 2xl:text-lg lg:text-sm font-Slab border-primary-900"
                  borderRadiusClass="rounded-md "
                  children="Get started"
                />
              </Link>
              <Button
                type="secondary"
                Typeof="button"
                onClick={toggleVideo}
                padding="2xl:px-2 px-1 py-2"
                width=" xl:w-40 2xl:w-44 lg:w-32"
                color="bg-white"
                className="border shadow-md xl:text-base 2xl:text-lg lg:text-sm font-Slab border-primary-900"
                borderRadiusClass="rounded-md "
                textcolor="text-primary-50"
                children={
                  <>
                    Product Demo{" "}
                    <Image
                      src={
                        isVideoPlaying
                          ? "/images/pause.svg"
                          : "/images/play_arrow.svg"
                      }
                      className="relative top-px"
                      width={20}
                      height={20}
                      alt="play"
                      loading="lazy"
                    />{" "}
                  </>
                }
              />
            </div>
          </div>
          <div className="relative flex items-start justify-start w-full lg:w-3/5">
            <div className="lg:h-auto relative justify-end flex  items-start w-full h-[300px] lg:aspect-w-6 lg:aspect-h-5 ">
              <div className="absolute z-20 p-4 overflow-hidden bg-white lg:top-14 2xl:top-12 top-5 right-1/3 sm:-top-12 sm:right-48 lg:right-1/4 lg:-translate-x-4 xl:-translate-x-7 2xl:-translate-x-5 w-44 h-52 sm:w-80 sm:h-80 lg:p-5 lg:w-60 lg:h-72 xl:w-72 xl:h-80 2xl:w-80 2xl:h-96 2xl:scale-95 shadow-4 rounded-2xl lg:rounded-3xl">
                <video
                  className="w-full h-full cursor-pointer "
                  onClick={toggleVideo}
                  src={productDemoUrl}
                  ref={videoRef}
                  autoPlay
                  loop
                  playsInline
                />
              </div>

              <div className="absolute z-10 h-40 bg-white w-36 sm:w-48 sm:h-56 sm:right-6 lg:w-48 lg:h-52 xl:w-60 2xl:h-64 xl:h-60 bottom-5 sm:-bottom-7 lg:bottom-40 xl:bottom-44 2xl:bottom-44 2xl:-translate-y-2 right-5 lg:right-0 lg:rounded-3xl rounded-2xl shadow-4">
                <Image
                  src="/images/half_side.svg"
                  className="absolute bottom-0 origin-bottom scale-50 sm:scale-75 sm:right-8 -right-px lg:right-4 xl:right-8 2xl:right-1/4 lg:scale-75 xl:scale-85 2xl:text-lg"
                  width={140}
                  height={300}
                  alt="half_side"
                  loading="lazy"
                />
              </div>

              <div>
                <Image
                  src="/images/vector_home.svg"
                  className="relative hidden origin-top-right bottom-8 lg:flex xl:scale-x-95 lg:scale-x-85 2xl:scale-x-110 lg:scale-y-85 xl:scale-y-85"
                  width={666}
                  height={600}
                  alt="vector_home"
                  loading="lazy"
                />
              </div>

              <div>
                <Image
                  src="/images/home_bg_mobile.svg"
                  className="absolute bottom-0 flex origin-bottom-right -right-4 scale-y-85 sm:scale-y-100 sm:-bottom-10 lg:hidden sm:right-0 sm:scale-x-150"
                  width={340}
                  height={320}
                  alt="vector_home"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center w-full mt-6 gap-x-8 sm:gap-x-14 lg:hidden lg:gap-x-6 xl:gap-x-6 2xl:gap-x-10">
            <Link href="/login">
              <Button
                type="primary"
                Typeof="button"
                padding="px-4 py-2"
                width=" xl:w-36 2xl:w-40 sm:w-40 "
                className="text-base border shadow-md lg:text-lg font-Slab border-primary-900"
                borderRadiusClass="rounded-md "
                children="Get started"
              />
            </Link>
            <Button
              type="secondary"
              Typeof="button"
              onClick={toggleVideo}
              padding="px-4 py-2"
              width=" w-full"
              color="bg-white"
              className="text-base border shadow-md lg:text-lg font-Slab border-primary-900"
              borderRadiusClass="rounded-md "
              textcolor="text-primary-50"
              children={
                <>
                  Product Demo{" "}
                  <Image
                    src={
                      isVideoPlaying
                        ? "/images/pause.svg"
                        : "/images/play_arrow.svg"
                    }
                    width={20}
                    height={20}
                    alt="play"
                    loading="lazy"
                  />{" "}
                </>
              }
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full gap-4 lg:pb-9 lg:pt-2 lg:gap-y-6 ">
          <Text
            htmlTag="h1"
            size="xl:text-3xl lg:text-2xl  text-sm sm:text-lg"
            weight="font-semibold"
            content="Navigate the Stock Market's Complexity with Ease"
          />

          <Text
            htmlTag="p"
            size="xl:text-lg lg:text-base text-xs"
            color="text-gray-500"
            fontfamily="font-sans"
            className="px-5 tracking-wide text-center lg:px-3 lg:w-3/4"
            content="We understand the challenges faced by traders in today's stock market. Whether you're a first-timer or a seasoned trader, we want to protect your capital, provide reliable trade ideas, and make your trading journey seamless and successful."
          />

          <span className="flex h-px bg-gray-300 lg:hidden shadow-2 rounded-xl w-90 "></span>
        </div>

        <div className="flex flex-col items-start justify-between w-full lg:flex-row h-[400px] sm:h-[450px] lg:h-[400px] xl:h-[450px] 2xl:h-[520px] lg:px-16 xl:px-32 2xl:px-36  bg-primary-900 ">
          <div className="relative flex items-start justify-start order-last w-full h-full px-5 py-3 sm:px-14 lg:px-0 sm:py-4 lg:order-first lg:py-6 xl:pt-8 xl:pb-12 2xl:pt-8 2xl:pb-20 lg:w-3/4">
            <div className="relative w-full lg:w-11/12 h-full 2xl:pb-[46%] flex justify-start">
              <Image
                src="/images/group_home.svg"
                alt="Adviser_Groups"
                className="object-contain object-center origin-top-left xl:scale-105 2xl:scale-110 lg:object-left "
                layout="fill"
              />
            </div>
          </div>

          <div className="flex flex-col items-start order-first h-auto px-5 pb-5 origin-top bg-white lg:h-full lg:order-last lg:w-1/3 lg:px-6 xl:pl-12 xl:pr-5 2xl:pr-5 gap-y-4 lg:gap-y-8 lg:pt-20 lg:pb-28 xl:pt-28 xl:pb-28 2xl:pt-32 2xl:pb-40 ">
            <Text
              htmlTag="h1"
              size="2xl:text-3xl lg:text-2xl text-xl"
              weight="font-semibold"
              content="Adviser Groups"
            />
            <Text
              htmlTag="p"
              size="xl:text-lg lg:text-base 2xl:text-xl"
              color="text-gray-500"
              weight="font-light"
              fontfamily="font-sans"
              className="tracking-tight"
              content="Get reliable trade ideas, experience one on one interactions with expert advisers, ask questions, discuss strategies, and gain valuable insights"
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-between w-full py-5 mt-5 mb-3 bg-gray-50 lg:bg-white lg:mb-0 lg:mt-0 lg:py-10 lg:items-start lg:flex-row 2xl:pb-14 lg:px-16 xl:px-32 2xl:px-36 ">
          <div className="flex flex-col items-start min-h-full px-5 pb-5 lg:px-0 lg:w-2/5 2xl:w-1/3 2xl:gap-y-8 gap-y-4 lg:gap-y-6 lg:pt-12 xl:pt-16 2xl:pt-20 ">
            <Text
              htmlTag="h1"
              size="2xl:text-3xl lg:text-2xl text-xl"
              weight="font-semibold"
              content="One-Click Execution"
            />
            <Text
              htmlTag="p"
              size="xl:text-lg lg:text-base 2xl:text-xl"
              color="text-gray-500"
              weight="font-light"
              fontfamily="font-sans"
              content="No more manual trade execution. Experience hassle-free and error-proof trading"
            />
          </div>
          <div className="relative w-4/5 sm:w-3/4 lg:w-[36%] ">
            <video
              className="w-full h-full "
              src={oneClickUrlExecution}
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
        </div>

        <div className="flex flex-col items-start justify-between w-full lg:flex-row h-[400px] sm:h-[450px] lg:h-[400px] xl:h-[450px] 2xl:h-[520px] lg:px-16 xl:px-32 2xl:px-36  bg-primary-900">
          <div className="relative flex items-start justify-start order-last w-full h-full px-5 py-3 sm:px-14 lg:px-0 sm:py-4 lg:order-first lg:py-6 xl:pt-8 xl:pb-12 2xl:pt-8 2xl:pb-20 lg:w-3/4">
            <div className="relative w-full lg:w-11/12 h-full 2xl:pb-[46%] flex justify-start ">
              <Image
                src="/images/group_perform.svg"
                className="object-contain object-center origin-top-left xl:scale-105 2xl:scale-110 lg:object-left "
                layout="fill"
                priority
              />
            </div>
          </div>
          <div className="flex flex-col items-start order-first h-auto px-5 pb-5 origin-top bg-white lg:h-full lg:order-last lg:w-1/3 lg:px-6 xl:pl-12 xl:pr-5 2xl:pr-5 gap-y-4 lg:gap-y-8 lg:pt-20 lg:pb-28 xl:pt-28 xl:pb-28 2xl:pt-32 2xl:pb-40">
            <Text
              htmlTag="h1"
              size="2xl:text-3xl lg:text-2xl text-xl"
              weight="font-semibold"
              content="Performance Tracking"
            />
            <Text
              htmlTag="p"
              size="xl:text-lg lg:text-base 2xl:text-xl"
              color="text-gray-500"
              weight="font-light"
              fontfamily="font-sans"
              className="tracking-tight"
              content="Complete visibility into your trading performance, track every tip’s outcome, and make data-driven decisions. "
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-between w-full py-5 mt-5 mb-3 bg-gray-50 lg:bg-white lg:mt-0 lg:mb-0 lg:py-10 lg:items-start lg:flex-row 2xl:pt-20 2xl:pb-14 lg:px-16 xl:px-32 2xl:px-36 ">
          <div className="flex flex-col items-start min-h-full px-5 pb-5 lg:w-2/5 2xl:w-1/3 lg:px-0 2xl:gap-y-8 gap-y-4 lg:gap-y-6 lg:pt-5 ">
            <Text
              htmlTag="h1"
              size="2xl:text-3xl lg:text-2xl text-xl"
              weight="font-semibold"
              content="Capital Protection"
            />
            <Text
              htmlTag="p"
              size="xl:text-lg lg:text-base 2xl:text-xl"
              color="text-gray-500"
              weight="font-light"
              fontfamily="font-sans"
              content="Minimize losses and maximize gains.Our certified advisers prioritize your capital protection. Choose peace of mind"
            />
          </div>
          <div className="relative w-4/5 sm:w-3/4 lg:w-[36%]">
            <Image
              src="/images/capital.svg"
              className="w-full h-full "
              width={100}
              height={100}
              alt="vector_home"
              loading="lazy"
            />
          </div>
        </div>

        <div className="flex flex-col items-start justify-between w-full lg:flex-row h-[400px] sm:h-[450px] lg:h-[400px] xl:h-[450px] 2xl:h-[520px] lg:px-16 xl:px-32 2xl:px-36  bg-primary-900 ">
          <div className="relative flex items-start justify-start order-last w-full h-full px-5 py-3 sm:px-14 lg:px-0 sm:py-4 lg:order-first lg:py-6 xl:pt-8 xl:pb-12 2xl:pt-8 2xl:pb-20 lg:w-3/4">
            <div className="relative w-full lg:w-11/12 h-full 2xl:pb-[46%] flex justify-start ">
              <Image
                src="/images/group_dir.svg"
                className="object-contain object-center origin-top-left xl:scale-105 2xl:scale-110 lg:object-left "
                layout="fill"
                priority
              />
            </div>
          </div>
          <div className="flex flex-col items-start order-first h-auto px-5 pb-5 origin-top bg-white lg:h-full lg:order-last lg:w-1/3 lg:px-6 xl:pl-12 xl:pr-5 2xl:pr-5 gap-y-4 lg:gap-y-8 lg:pt-20 lg:pb-28 xl:pt-28 xl:pb-28 2xl:pt-32 2xl:pb-40 ">
            <Text
              htmlTag="h1"
              size="2xl:text-3xl lg:text-2xl text-xl"
              weight="font-semibold"
              content="Adviser Directory"
            />
            <Text
              htmlTag="p"
              size="xl:text-lg lg:text-base 2xl:text-xl"
              color="text-gray-500"
              weight="font-light"
              fontfamily="font-sans"
              content="Effortlessly find and evaluate certified advisers with our Digital Adviser Directory, making adviser selection a breeze "
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-between lg:items-start w-full mt-5 mb-3 lg:flex-row h-[400px] lg:mb-0 lg:mt-0 sm:h-[450px] lg:h-[400px] xl:h-[450px] 2xl:h-[520px] lg:px-16 xl:px-32 2xl:px-36 lg:bg-white bg-gray-50  ">
          <div className="flex flex-col items-start h-auto px-5 pt-5 origin-top lg:h-full lg:w-1/3 lg:px-6 xl:pl-12 xl:pr-5 2xl:pr-5 gap-y-4 lg:gap-y-8 lg:pt-20 lg:pb-28 xl:pt-28 xl:pb-28 2xl:pt-32 2xl:pb-40 ">
            <Text
              htmlTag="h1"
              size="2xl:text-3xl lg:text-2xl text-xl"
              weight="font-semibold"
              content="Learn Trading"
            />
            <Text
              htmlTag="p"
              size="xl:text-lg lg:text-base 2xl:text-xl"
              color="text-gray-500"
              weight="font-light"
              fontfamily="font-sans"
              content="Start your exciting trading journey with bite-sized quizzes"
            />
          </div>
          <div className="relative flex items-start justify-end w-full h-full px-5 py-5 sm:py-10 sm:px-14 lg:px-0 lg:py-6 xl:pt-8 xl:pb-12 2xl:pt-8 2xl:pb-20 lg:w-3/4">
            <div className="relative w-full  lg:w-11/12 h-full 2xl:pb-[46%] flex justify-start ">
              <Image
                src="/images/learn.svg"
                className="object-contain object-center origin-top-right xl:scale-105 2xl:scale-110 lg:object-right"
                layout="fill"
                priority
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start justify-center w-full py-4 lg:gap-x-40 xl:gap-x-12 2xl:gap-x-44 bg-primary-900 lg:flex-row gap-y-3 lg:py-6 xl:py-6 2xl:py-8 lg:px-16 xl:px-32 2xl:px-36">
          {homePrivacy.map((home, index) => (
            <span
              key={home.title}
              className={`lg:flex flex-col ${
                index === showIndex ? "flex" : "hidden"
              } overflow-hidden text-center w-full 2xl:w-1/2 xl:w-2/5 lg:w-1/2 gap-y-2 px-5 sm:px-36 lg:px-0  justify-center  items-center`}
            >
              <Image
                src={home.imageSrc}
                width={35}
                height={42}
                loading="lazy"
                className="mb-3 "
              />
              <Text
                htmlTag="h1"
                size=" text-xl"
                weight="font-semibold"
                color="text-white"
                content={home.title}
              />
              <Text
                htmlTag="p"
                size="text-base"
                className="text-slate-300"
                content={home.content}
              />
            </span>
          ))}

          <div className="flex items-center justify-center w-full gap-x-3 lg:hidden">
            <span
              onClick={() => setShowIndex(0)}
              className={`w-2 h-2 cursor-pointer rounded-full ${
                showIndex == 0 ? "bg-white" : "bg-gray-500"
              }`}
            ></span>
            <span
              onClick={() => setShowIndex(1)}
              className={`w-2 h-2 cursor-pointer rounded-full ${
                showIndex == 1 ? "bg-white" : "bg-gray-500"
              }`}
            ></span>
            <span
              onClick={() => setShowIndex(2)}
              className={`w-2 h-2 cursor-pointer rounded-full ${
                showIndex == 2 ? "bg-white" : "bg-gray-500"
              }`}
            ></span>
          </div>
        </div>

        <div className="w-full px-3 mt-8 mb-5 lg:px-16 xl:px-32 2xl:px-36">
          <div className="relative flex items-center justify-between w-full pl-5 overflow-hidden rounded-lg lg:h-32 h-28 lg:pl-12 lg:rounded-2xl bg-primary-700">
            <div className="flex flex-col items-start w-3/5 py-5 2xl:w-1/3 lg:w-5/12 gap-y-2 lg:py-4">
              <Text
                htmlTag="h1"
                size="2xl:text-2xl lg:text-2xl text-lg"
                color="lg:text-black text-primary-50"
                weight="font-bold"
                content="Vibrant Community"
              />
              <Text
                htmlTag="p"
                size="xl:text-base lg:text-base 2xl:text-base text-xs "
                color="lg:text-gray-600 text-primary-50"
                fontfamily="font-sans"
                weight="font-medium"
                content="Engage with like-minded traders and investors, exchange ideas, share knowledge, and learn."
              />
            </div>
            <div className="items-center justify-center pr-2 lg:flex lg:w-1/4 sm:pr-4 lg:pr-4">
              <Button
                type="secondary"
                Typeof="button"
                padding="lg:px-4 px-2 lg:py-2 py-1"
                width=" w-full"
                className="z-20 w-32 text-xs bg-white xl:text-lg lg:text-base xl:w-48 lg:w-36 shadow-5 font-Slab"
                borderRadiusClass="rounded-md "
                textcolor="text-primary-50"
                onClick={handleTelegram}
                children={
                  <>
                    <div className="flex items-center justify-center w-full h-full gap-x-2">
                      Join Now{" "}
                      <Image
                        src="/images/telegram_icon.svg"
                        width={25}
                        height={25}
                        alt="telegram_icon"
                        loading="lazy"
                        className=" scale-80 lg:scale-100"
                      />{" "}
                    </div>
                  </>
                }
              />
            </div>

            <Image
              src="/images/join_community_bg.svg"
              className="absolute right-0 z-10 hidden origin-right lg:scale-x-80 xl:scale-100 lg:flex"
              width={500}
              height={140}
              alt="community_bg"
              loading="lazy"
            />
            <Image
              src="/images/join_community_bg_1.svg"
              className="absolute right-0 hidden origin-right lg:scale-x-80 xl:scale-100 lg:flex"
              width={500}
              height={140}
              alt="community_bg"
              loading="lazy"
            />
            <Image
              src="/images/join_community_bg_mobile.svg"
              className="absolute right-0 origin-right scale-y-125 lg:hidden sm:scale-x-125"
              width={150}
              height={90}
              alt="community_bg"
              loading="lazy"
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center w-full py-10 mt-4 bg-primary-200 lg:mt-8">
          <Text
            htmlTag="h1"
            size="2xl:text-2xl lg:text-2xl text-base"
            weight="font-semibold"
            content="Frequently asked questions"
          />
          <div className="flex flex-col w-full px-5 mt-6 lg:w-3/4 sm:px-8 gap-y-6 lg:mt-20">
            {faqs.map((faq, index) => (
              <span
                className="flex flex-col w-full cursor-pointer gap-x-2 lg:gap-y-2"
                key={index}
                onClick={() => handleFAQItemClick(index)}
              >
                <span className="flex justify-between w-full">
                  <Text
                    htmlTag="h1"
                    size=" text-xs md:text-sm lg:text-lg"
                    weight="font-semibold"
                    color="text-gray-700"
                    content={faq.question}
                  />
                  <Image
                    className={`${
                      FAQClicked[index] && "hidden"
                    } origin-top scale-75 lg:scale-100 `}
                    src={"/images/add_gray.svg"}
                    width={20}
                    height={20}
                    alt="show"
                    loading="lazy"
                  />
                </span>
                <h1
                  className={`py-2 px-2 lg:pl-2 lg:pr-12 text-xs md:text-xs lg:text-base font-roboto font-medium text-gray-600 ${
                    !FAQClicked[index] && "hidden"
                  }`}
                >
                  {faq.answer}
                </h1>
                <h1
                  className={` mt-2 h-px w-full px-20 bg-gray-400 ${
                    index != 2 ? "flex" : "hidden"
                  }`}
                ></h1>
              </span>
            ))}
          </div>
          <Link
            href="/faq"
            className="px-4 py-2 mt-6 text-sm font-medium text-gray-800 bg-transparent border border-gray-700 rounded-md lg:text-lg font-Slab "
          >
            View more
          </Link>
        </div>

        <div className="relative flex flex-col items-center w-full px-5 pt-5 pb-8 mb-2 bg-white lg:pt-12 lg:pb-14 md:mb-8 md:mt-5 ">
          <Subscribe />
        </div>
      </div>
    </>
  );
}

export default Homepage;
