export const faqs = [
  {
    question: "Is BTST free to use?",
    answer: `BTST offers both free and premium groups managed by advisers. Access to the platform is free, allowing you to browse and connect with advisers. However, there may be subscription fees for accessing certain premium features or joining exclusive communities.`,
  },
  {
    question: "Is my personal and financial information secure on BTST?",
    answer: `BTST takes the security and privacy of user information seriously. We implement robust measures to protect your personal and financial data in accordance with industry standards and data protection regulations.`,
  },
  {
    question: "How can I join BTST as an adviser?",
    answer: `If you are a SEBI-registered Research Analyst (RA) or Registered Investment Adviser (RIA), you can apply to join BTST as an adviser. Simply visit our website, Click on "Signup as Adviser" and follow the application process to create your adviser profile.`,
  },
];

export const homePrivacy = [
  {
    imageSrc: "/images/fingerprint.svg",
    title: "Safe & Secure",
    content: "Rest Assured knowing that your data is secure with us.",
  },
  {
    imageSrc: "/images/privacy.svg",
    title: "Privacy",
    content: "We will never share your private data without your consent.",
  },
  {
    imageSrc: "/images/transparent.svg",
    title: "Transparent",
    content:
      "Transparency in the information provided by advisers including any conflicts of interest.",
  },
];
